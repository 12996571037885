import { request } from "@/api/service";
import { request as requestExport } from "@/api/serviceExport";
import store from "@/store";
// 上传-导入
const AsyncUpload = (url, data) => {
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: "post",
      headers: {
        "Access-Token": store.getters.info && store.getters.info.token,
        "Content-Type": "multipart/form-data"
      },
      data
    })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};
function urlDownload(url, data, filenName) {
  return new Promise((resolve, reject) => {
    requestExport({
      url: url,
      method: "post",
      data
    })
      .then(res => {
        const link = document.createElement("a");
        const blob = new Blob([res]);
        let reader = new FileReader();
        reader.readAsText(blob, "utf-8");
        reader.onload = function() {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob);
          } else {
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", filenName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          resolve("success");
        };
      })
      .catch(error => {
        reject(error);
      });
  });
}
function downloadFile(url, data, filenName) {
  return new Promise((resolve, reject) => {
    requestExport({
      url: url,
      method: "get",
      data
    })
      .then(res => {
        const link = document.createElement("a");
        let reader = new FileReader();
        reader.readAsText(res, "utf-8");
        reader.onload = function() {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(res);
          } else {
            link.style.display = "none";
            link.href = URL.createObjectURL(res);
            link.setAttribute("download", filenName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          resolve("success");
        };
      })
      .catch(error => {
        reject(error);
      });
  });
}
// 下载模板
function download(url, data, filenName, suffix) {
  return new Promise((resolve, reject) => {
    requestExport({
      url: url,
      method: "post",
      data
    })
      .then(res => {
        const link = document.createElement("a");
        const blob = new Blob([res]);
        let reader = new FileReader();
        reader.readAsText(blob, "utf-8");
        reader.onload = function() {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob);
          } else {
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", filenName + "." + suffix);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          resolve("success");
        };
      })
      .catch(error => {
        reject(error);
      });
  });
}
// 预览
function preview(url, data, filenName, suffix) {
  return new Promise((resolve, reject) => {
    requestExport({
      url: url,
      method: "post",
      data
    })
      .then(res => {
        let reader = new FileReader();
        reader.onload = e => {
          // 预处理
          let binary = "";
          const buf = new Uint8Array(e.target.result);
          const length = buf.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(buf[i]);
          }
          resolve(binary);
        };
        reader.readAsArrayBuffer(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}
// 导出
function exportBtn(url, data, filenName, suffix) {
  return new Promise((resolve, reject) => {
    requestExport({
      url: url,
      method: "post",
      data
    })
      .then(res => {
        const blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const objectUrl = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = objectUrl;
        a.download = filenName + "." + suffix;
        a.click();
        a = null;
        URL.revokeObjectURL(objectUrl);
        resolve("success");
      })
      .catch(error => {
        reject(error);
      });
  });
}

export default {
  AsyncUpload,
  download,
  urlDownload,
  downloadFile,
  exportBtn,
  preview
};
