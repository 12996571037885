<template>
  <div class="h-flex h-font-sm h-primary-color">
    <div class="h-p-r-5" style="position: relative;top: -1px;">{{ elProps.title }}</div>
    <van-loading v-if="pageData.ocrLoding" type="spinner" size="20px" color="#57ccc2" />
    <van-uploader v-else :after-read="handleFileOcr" max-count="1"><van-icon name="scan" size="20"/></van-uploader>
  </div>
</template>
<script>
import store from "@/store";
import ImagesQuicklyCompress from "images-quickly-compress"; // 导出：压缩图片插件
import { request } from "@/api/service";
import Common from "@/api/common/index";
export default {
  name: "OcrConvert",
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      pageData: {
        ocrLoding: false
      }
    };
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          title: "拍摄或上传识别",
          type: null,
          upload: null
        },
        this.config
      );
    }
  },
  methods: {
    async handleFileOcr(upLoadObj) {
      this.pageData.ocrLoding = true;
      let { file } = upLoadObj;
      let isLt5M = file.size / 1024 / 1024 < 5;
      const suffixMap = {
        "image/jpeg": ".jpeg",
        "image/jpg": ".jpg",
        "image/png": ".png"
      };
      if (!isLt5M) {
        if (!(suffixMap[file.type] && [".jpg", ".png", ".jpeg"].includes(suffixMap[file.type]))) {
          this.$toast("只能上传5M以下的图片");
          this.pageData.ocrLoding = false;
          return;
        }
        if (suffixMap[file.type] && [".jpg", ".png", ".jpeg"].includes(suffixMap[file.type])) {
          // 超过5m的图片文件, 启用压缩
          const compressFiles = await new ImagesQuicklyCompress({
            mode: "pixel", // 根据像素总大小压缩
            num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
            size: "500kb", // 图片大小超过500kb执行压缩
            imageType: file.type, // jpeg压缩效果十分理想
            quality: 0.8,
            orientation: false
          }).compressor([file]);
          isLt5M = compressFiles[0].size / 1024 / 1024 < 5;
          file = new window.File([compressFiles[0]], file.name, {
            type: compressFiles[0].type
          });
        }
        if (!isLt5M) {
          this.pageData.ocrLoding = false;
          this.$toast("请上传5M以下的图片");
          return;
        }
      }
      // 通过 FormData 对象上传文件
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", this.elProps.type);
      // 发起请求
      request({
        url: "/afis-engine/ocr/recognition",
        method: "post",
        data: formData,
        headers: {
          "Access-Token": store.getters.info && store.getters.info.token,
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          this.pageData.ocrLoding = false;
          // 设置值
          this.$emit("ocrVal", res);
          if (!this.elProps.upload) {
            return false;
          }
          this.handleFileUpload(file, this.elProps.upload, resImg => {
            this.$emit("uploadVal", resImg);
          });
        })
        .catch(err => {
          this.pageData.ocrLoding = false;
          this.$toast(err.message || "上传失败");
        });
    },
    async handleFileUpload(file, extraParam, success) {
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        // this.$message.error('请上传10M以下的文件')
        return false;
      }
      // 通过 FormData 对象上传文件
      const formData = new FormData();
      formData.append("file", file);
      formData.append("busiType", "productImage");
      formData.append("category", "public");
      // 发起请求
      const res = await Common.AsyncUpload("/afis-engine/dfs/storage/upload", formData);
      const fileObj = {
        fileId: res.fileId,
        filePath: res.fileUrl,
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        name: file.name,
        url: res.fileUrl,
        isImage: true
      };
      const resObj = Object.assign(fileObj, extraParam);
      success(resObj);
    }
  }
};
</script>
<style lang="scss"></style>
